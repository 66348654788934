import React, { Component } from "react";
import { Link } from "react-router-dom";
import SearchForm from "./../widgets/SearchForm";
import logo from "./../images/logo.png";
import facebook1 from "./../images/facebook-icon.svg";
import instagram1 from "./../images/instagram-icon.svg";
import outline1 from "./../images/outline-icon.svg";
import youtube1 from "./../images/youtube-icon.svg";
import linkedin1 from "./../images/linkedin-icon.svg";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import { HomeService } from "./../services/home";
import { dateFormat ,AppContext} from "./../config/common"; 

import "./../css/Header.css";
import Catalog from "../Catalog";

class Header extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      category: [
        {
          title:"Direct Tax and Laws",
          slug:"direct-tax-and-laws"
        },
        {
          title:"Competition Laws",
          slug:"competition-laws"
        },
        {
          title:"Company Laws",
          slug:"company-laws"
        },
        {
          title:"Indirect Tax",
          slug:"indirect-tax"
        },
        {
          title:"International Tax",
          slug:"international-tax"
        },
        {
          title:"Accounts & Audit",
          slug:"accounts-and-Audit"
        },
        {
          title:"Indian Acts & Rules",
          slug:"indian-acts-and-rules"
        }
      ],
      headerClass: "head-outer", 
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
    // this.fetchCategory();
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  // fetchCategory = async () => {
  //   const category = await HomeService.fetchCategory();
  //   this.setState({ category });
  // };
  handleScroll = async (e) => {
    const headerClass =
      window.scrollY > 52 ? "head-outer stiky-header" : "head-outer";
    this.setState({ headerClass });
  };
  handleSubmit = async (e) => {
    // e.preventDefault();
    console.log("Form submitted:");
  };
  render() { 
    const { settings, socialIcons } = this.context;
    return (
      <>
        <div className={this.state.headerClass}>
          <div className="head-top">
          
            <div className=" container">
              <div className="head-top-row">
                <div className="head-top-left">
                  <p>{dateFormat(new Date(), "l, d F, Y")}</p>
                </div>
                <div className="head-top-right">
                  <ul className="social-ul">
                    {socialIcons?.facebook ? (
                      <li>
                        <Link to={socialIcons?.facebook ?? "#"} target="_blank">
                          <img src={facebook1} alt="logo" />
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}
                    {socialIcons?.instagram ? (
                      <li>
                        <Link
                          to={socialIcons?.instagram ?? "#"}
                          target="_blank"
                        >
                          <img src={instagram1} alt="instagram" />
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}
                    {socialIcons?.twitter ? (
                      <li>
                        <Link to={socialIcons?.twitter ?? "#"} target="_blank">
                          <img src={outline1} alt="X" />
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}
                    {socialIcons?.youtube ? (
                      <li>
                        <Link to={socialIcons.youtube ?? "#"} target="_blank">
                          <img src={youtube1} alt="youtube" />
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}
                    {socialIcons?.linkedIn && (
                      <li>
                        <Link to={socialIcons.linkedIn ?? "#"} target="_blank">
                          <img src={linkedin1} alt="LinkedIn" />
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="head-wrap">
            <div className=" container">
              <div className="head-nav">
                <Navbar expand="lg" className="bg-body-tertiary">
                  <Navbar.Brand href="/">
                    <img src={logo} alt="logo" />
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav >
                    <Link to={'/'} className="nav-link">{Catalog.Strings.menu.home}</Link>
                    <Link to={'/about'} className="nav-link">{Catalog.Strings.menu.about}</Link>
                    <Link to={'/products'} className="nav-link">{Catalog.Strings.menu.products}</Link>
                    <Link to={'/contact'} className="nav-link">{Catalog.Strings.menu.contact}</Link>
                       
                      {/* <Nav.Link href="/">{Catalog.Strings.menu.home}</Nav.Link>
                      <Nav.Link href="/about">{Catalog.Strings.menu.about}</Nav.Link>
                      <Nav.Link href="/products">{Catalog.Strings.menu.products}</Nav.Link>
                      <Nav.Link href="/contact">{Catalog.Strings.menu.contact}</Nav.Link> */}
                    </Nav>
                  </Navbar.Collapse>
                  {settings.show_auth ? (
                    <>
                    {Catalog.Session.isLogined?(

                  <Nav className="  login-ul">
                    <Link to={'/account'} className="nav-link">{Catalog.Strings.menu.account}</Link>
                    <Nav.Link onClick={()=>{
                      Catalog.Session={}; 
                      this.setState({})
                      window.history.go(0)
                    }}>{Catalog.Strings.menu.logout}</Nav.Link>
                  </Nav>
                    ):(

                    <Nav className="  login-ul">
                    <Link to={'/login'} className="nav-link">{Catalog.Strings.menu.login}</Link>
                    <Link to={'/signup'} className="nav-link">{Catalog.Strings.menu.signup}</Link>
                    {/* <Nav.Link href="/login">{Catalog.Strings.menu.login}</Nav.Link>
                    <Nav.Link href="/signup">{Catalog.Strings.menu.signup}</Nav.Link> */}
                  </Nav>
                    )}
                    
                    </>
                  ) : (
                    <></>
                  )}
                </Navbar>
              </div>
            </div>
          </div>
          <div className="search-wrap">
            <div className=" container">
              <SearchForm
                formClass="top-serch"
                placeholder={"Search from millions of judgments..."}
                handleSubmit={this.handleSubmit}
                categorys={
                  this.state.headerClass === "head-outer"
                    ? this.state.category
                    : null
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Header;
